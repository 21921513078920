import { ActivationSystemTaskDTO } from '../../api'
import { ActivationSystemTaskState } from '../../enums'
import { ActivationSystemTask } from '../interfaces'

/**
 * Map the given `ActivationSystemTaskDTO` to `ActivationSystemTask`
 * @param task - Task to map
 */
export const mapTaskFromDTO = (task: ActivationSystemTaskDTO): ActivationSystemTask => {
    const completed = task.state !== ActivationSystemTaskState.NotCompleted

    return {
        completed,
        id: task.id,
        kind: task.kind,
        name: task.name,
        url: task.url
    }
}

/**
 * Map the given `ActivationSystemTask` to `ActivationSystemTaskDTO`
 * @param task - Task to map
 */
export const mapTaskToDTO = (task: ActivationSystemTask): ActivationSystemTaskDTO => {
    const state = task.completed ? ActivationSystemTaskState.Completed : ActivationSystemTaskState.NotCompleted

    return {
        state,
        id: task.id,
        kind: task.kind,
        name: task.name,
        url: task.url
    }
}
