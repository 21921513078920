import {
    CHAT_ROUTER_BASE_ROUTE,
    CHAT_ROUTER_CONVERSATION_ROUTE,
    CHAT_ROUTER_PATIENTS_ROUTE,
    CHAT_ROUTER_RESOLVE_ALL_ROUTE
} from '@/features/chat/ui/constants/chat.router.constants'
import uninav from '@/features/navigation/ui/router/uninav.middleware'

import { useRequests } from '../../composable/useRequests'
import { PAGES } from '../constants/chat.constants'

const { hasDpPhoneIntegrated, shouldAccessNewChat } = useRequests()

const ChatBase = () => {
    if (shouldAccessNewChat()) {
        return import(/* webpackChunkName: "w-chat-base" */ '../views/WChat.vue')
    }
    return import(/* webpackChunkName: "chat-base" */ '../views/Chat.vue')
}
const PatientChatConversationList = () => {
    if (shouldAccessNewChat()) {
        return import(/* webpackChunkName: "w-patient-chat-conversation-list" */ '../views/WChatConversationList.vue')
    }
    return import(/* webpackChunkName: "patient-chat-conversation-list" */ '../views/PatientChatConversationList.vue')
}
const SaasInternalChatConversationList = () => {
    if (shouldAccessNewChat()) {
        return import(
            /* webpackChunkName: "w-saas-internal-chat-conversation-list" */ '../views/WTeamChatConversationList.vue'
        )
    }
    return import(
        /* webpackChunkName: "saas-internal-chat-conversation-list" */ '../views/SaasInternalChatConversationList.vue'
    )
}
const RequestsFeatureActivation = () =>
    import(/* webpackChunkName: "requests-feature-activation" */ '../views/RequestsFeatureActivation.vue')

const ChatConversation = () =>
    import(/* webpackChunkName: "w-patient-chat-conversation-list" */ '../views/WChatConversation.vue')

const ChatConversationResolveAll = () =>
    import(/* webpackChunkName: "patient-chat-conversation-resolve-all" */ '../views/WChatConversationResolveAll.vue')

const beforeEnterChat = (to, from, next) => {
    if (hasDpPhoneIntegrated()) {
        window.open('http://phone.miodottore.it/', '_blank')
        return next('/')
    }
    return next()
}

const beforeEnterRequest = ({ name }, from, next) => {
    if (shouldAccessNewChat() || hasDpPhoneIntegrated()) return next('/')
    return next()
}

const beforeEnterConversation = (to, from, next) => {
    // open conversation from patient profile
    if (!from.path.includes(CHAT_ROUTER_BASE_ROUTE)) {
        to.params.openConversation = 'true'
    }
    return next()
}

export default {
    path: CHAT_ROUTER_BASE_ROUTE,
    component: ChatBase,
    children: [
        {
            path: `${CHAT_ROUTER_PATIENTS_ROUTE}/${CHAT_ROUTER_CONVERSATION_ROUTE}/:patientId`,
            name: PAGES.PATIENT_CHAT_CONVERSATION,
            query: {},
            component: ChatConversation,
            beforeEnter: beforeEnterConversation
        },
        {
            path: `${CHAT_ROUTER_PATIENTS_ROUTE}/${CHAT_ROUTER_RESOLVE_ALL_ROUTE}`,
            name: PAGES.RESOLVE_ALL_PATIENTS_REQUEST,
            component: ChatConversationResolveAll
        },
        {
            // patientId param supports opening a conversation from email by patientId / old redirections
            path: `${CHAT_ROUTER_PATIENTS_ROUTE}/:patientId?`,
            alias: `${CHAT_ROUTER_PATIENTS_ROUTE}/:folder/:patientId`,
            name: PAGES.PATIENTS_CONVERSATION_LIST,
            component: PatientChatConversationList,
            beforeEnter: beforeEnterChat
        },
        {
            path: 'requests',
            name: PAGES.REQUESTS_MESSAGES_LIST,
            component: RequestsFeatureActivation,
            beforeEnter: beforeEnterRequest
        },
        {
            path: 'saas-internal',
            name: PAGES.SAAS_INTERNAL_CONVERSATION_LIST,
            component: SaasInternalChatConversationList,
            beforeEnter: beforeEnterChat
        },
        {
            path: '',
            redirect: {
                name: PAGES.PATIENTS_CONVERSATION_LIST
            }
        }
    ],
    meta: {
        title: 'chat-for-doctors-main-title-messages',
        middleware: uninav,
        uninavName: 'messages'
    }
}
