import { ActivationSystemGroupTask, ActivationSystemTask } from '../interfaces'

/**
 * Map the given `ActivationSystemTask` to `ActivationSystemGroupTask`
 * @param task - Task to map
 */
export const mapGroupTaskFromTask = (task: ActivationSystemTask): ActivationSystemGroupTask => {
    return {
        completed: task.completed,
        id: task.id,
        name: task.name,
        url: task.url
    }
}
