import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import {
    ActionTypes as RequestActionTypes,
    GetterTypes as RequestGetterTypes,
    MODULE_REQUEST
} from '@/features/chat/ui/store/request/types'
import {
    ActionTypes as ChatActionTypes,
    GetterTypes as ChatGetterTypes,
    MODULE_CHAT
} from '@/features/chat/ui/store/types'
import { DEFAULT_CATEGORIES_SET } from '@/features/settings/ui/constants/patientRequest'
import store from '@/store'

import { INTEGRATION_CHANNEL, REQUEST_INTEGRATION_CHANNEL } from '../ui/constants/requests.constants'

export function useRequests() {
    function hasDpPhoneIntegrated(): boolean {
        if (
            store.getters[`${MODULE_REQUEST}/${RequestGetterTypes.GetRequestChannel}`] ===
            REQUEST_INTEGRATION_CHANNEL[INTEGRATION_CHANNEL.dpPhone]
        ) {
            return true
        }

        return ensureToggleIsEnabled('DoctorIntegratedWithDpPhone', true)
    }

    function getIfUserDoctorType(): boolean {
        return store.getters[`${AuthGetterTypes.IsDoctor}`]
    }

    function getIfUserSecretaryType(): boolean {
        return store.getters[`${AuthGetterTypes.IsSecretary}`]
    }

    function getIfUserGruppoType(): boolean {
        return store.getters[`${AuthGetterTypes.IsGruppo}`]
    }

    function getIfUserPublicPractitionerSecretary(): boolean {
        return store.getters[`${AuthGetterTypes.IsSecretaryOfPublicPractitioner}`]
    }

    function getIfDoctorIsPublicPractitioner(): boolean {
        return getIfUserDoctorType() && store.getters[`${AuthGetterTypes.IsPublicPractitioner}`]
    }

    function getIfUserGruppoSecretary(): boolean {
        return store.getters[`${AuthGetterTypes.IsSecretaryOfGruppo}`]
    }

    function getIsRequestEnabled(): boolean {
        return store.getters[`${MODULE_REQUEST}/${RequestGetterTypes.GetIsRequestFeatureEnabled}`]
    }

    function getIsContactEnabled(): boolean {
        return store.getters[`${MODULE_CHAT}/${ChatGetterTypes.GetIsContactFromProfileAllowed}`]
    }

    function getIsGlobalBlockMessageActivated(): boolean {
        return store.getters[`${MODULE_CHAT}/${ChatGetterTypes.GetIsGlobalMessageBlockActive}`]
    }

    function getIfDoctorHasAccessToRequestFeature(): boolean {
        return (
            getIfDoctorIsPublicPractitioner() ||
            (ensureToggleIsEnabled('ShowRequestFeature', true) && getIfUserDoctorType())
        )
    }

    function getIfUserSecretaryWithGPfeatureAccess(): boolean {
        return (
            (getIfUserSecretaryType() && ensureToggleIsEnabled('ShowRequestFeature', true)) ||
            getIfUserPublicPractitionerSecretary()
        )
    }

    function getIfSecretaryHasAccessToRequest(): boolean {
        return getIfUserSecretaryWithGPfeatureAccess() && store.getters[`${AuthGetterTypes.HasAccessToMessages}`]
    }

    function canCheckRequestFeatureEnabled(): boolean {
        return getIfUserSecretaryWithGPfeatureAccess() || getIfDoctorHasAccessToRequestFeature()
    }

    function getGruppoIsIntegratedWithDpPhone(): boolean {
        return getIfUserGruppoType() && ensureToggleIsEnabled('DoctorIntegratedWithDpPhone', true)
    }

    function getSecretaryGruppoIsIntegratedWithDpPhone(): boolean {
        return getIfUserGruppoSecretary() && ensureToggleIsEnabled('DoctorIntegratedWithDpPhone', true)
    }

    function getIfUserHasAccessToReadRequests(): boolean {
        const hasDoctorAccess = getIfDoctorHasAccessToRequestFeature()
        const hasSecretaryAccess =
            (getIfSecretaryHasAccessToRequest() || getIfUserGruppoSecretary()) && getIsRequestEnabled()
        return hasDoctorAccess || hasSecretaryAccess
    }

    function getIfUserHasAccessToBlockPatient(): boolean {
        const hasDoctorAccess = getIfDoctorHasAccessToRequestFeature()
        const hasGruppoAccess = getGruppoIsIntegratedWithDpPhone() || getSecretaryGruppoIsIntegratedWithDpPhone()
        const hasSecretaryAccess = getIfSecretaryHasAccessToRequest()
        return hasDoctorAccess || hasSecretaryAccess || hasGruppoAccess
    }

    function shouldAccessNewChat(): boolean {
        return (
            (getIfDoctorHasAccessToRequestFeature() ||
                getIfSecretaryHasAccessToRequest() ||
                getIfUserGruppoSecretary() ||
                getIfUserGruppoType()) &&
            ensureToggleIsEnabled('NewInboxView', true)
        )
    }

    function shouldAccessNewConversationView(): boolean {
        if (!shouldAccessNewChat()) {
            return false
        }

        if (getIfUserGruppoSecretary()) {
            return ensureToggleIsEnabled('NewInboxConversationViewGruppoSecretaries', true)
        }

        return ensureToggleIsEnabled('NewInboxConversationView', true)
    }

    async function fetchIsContactEnabled() {
        const isContactEnabled = `${MODULE_CHAT}/${ChatActionTypes.FetchIsContactFromProfileAllowed}`
        await store.dispatch(isContactEnabled)
    }

    async function fetchRequestsIntegration(doctorId: string) {
        const requestIntegration = `${MODULE_REQUEST}/${RequestActionTypes.FetchRequestsIntegration}`
        await store.dispatch(requestIntegration, { docplannerDoctorId: doctorId })
    }

    async function updateContactFromProfileAllowed({ isAllowed }: { isAllowed: boolean }) {
        const setContactFromProfileAction = `${MODULE_CHAT}/${ChatActionTypes.UpdateIsContactFromProfileAllowed}`
        await store.dispatch(setContactFromProfileAction, { isAllowed })
    }

    async function setDefaultCategories(doctorId: string) {
        const setRequestCategories = `${MODULE_REQUEST}/${RequestActionTypes.UpdateRequestCategories}`

        await store.dispatch(setRequestCategories, {
            docplannerDoctorId: doctorId,
            categories: DEFAULT_CATEGORIES_SET
        })
    }

    async function updateRequestFeature(isEnabled: boolean, doctorId: string) {
        const setRequestEnabled = `${MODULE_REQUEST}/${RequestActionTypes.UpdateIsRequestFeatureEnabled}`

        await store.dispatch(setRequestEnabled, { isEnabled, doctorId })
    }

    async function updateStatusGlobalMessageBlock(shouldActivateBlock: boolean, doctorId: string) {
        const updateStatusGlobalBlock = `${MODULE_CHAT}/${ChatActionTypes.UpdateStatusGlobalMessageBlock}`
        await store.dispatch(updateStatusGlobalBlock, { shouldActivateBlock, doctorId })
    }

    async function switchRequests({
        doctorId,
        isEnabled,
        setBlock = true
    }: {
        doctorId: string
        isEnabled: boolean
        setBlock: boolean
    }) {
        const isBlockActive = getIsGlobalBlockMessageActivated()

        await updateRequestFeature(isEnabled, doctorId)
        if (!isBlockActive && setBlock) {
            await updateStatusGlobalMessageBlock(true, doctorId)
        }
    }

    async function enableRequestDefaults({ doctorId, setBlock }: { doctorId: string; setBlock: boolean }) {
        await Promise.all([switchRequests({ doctorId, isEnabled: true, setBlock }), setDefaultCategories(doctorId)])
    }

    return {
        canCheckRequestFeatureEnabled,
        enableRequestDefaults,
        fetchIsContactEnabled,
        fetchRequestsIntegration,
        hasDpPhoneIntegrated,
        getGruppoIsIntegratedWithDpPhone,
        getIsContactEnabled,
        getIsGlobalBlockMessageActivated,
        getIfDoctorHasAccessToRequestFeature,
        getIsRequestEnabled,
        getIfDoctorIsPublicPractitioner,
        getIfUserGruppoSecretary,
        getIfUserHasAccessToBlockPatient,
        getIfUserHasAccessToReadRequests,
        getIfUserPublicPractitionerSecretary,
        shouldAccessNewChat,
        switchRequests,
        updateContactFromProfileAllowed,
        updateRequestFeature,
        updateStatusGlobalMessageBlock,
        shouldAccessNewConversationView
    }
}
