export enum ActivationSystemTaskKind {
    Know = 0,
    Trust,
    Book
}

export enum ActivationSystemTaskScope {
    Booking = 'booking',
    MobileApp = 'mobile-app',
    Patient = 'patient',
    Profile = 'profile',
    Schedule = 'schedule',
    Service = 'service',
    User = 'user',
    VideoLibrary = 'video-library',
    Wizard = 'wizard'
}

export enum ActivationSystemTaskAction {
    Create = 'create',
    Download = 'download',
    Import = 'import',
    Show = 'show',
    Update = 'update'
}

export enum ActivationSystemTaskRouteType {
    Global = 'global',
    Local = 'local'
}

export enum ActivationSystemTaskURLAppType {
    SaaS = 0,
    Marketplace,
    OnboardingWizard
}

export enum ActivationSystemTaskState {
    NotCompleted = 0,
    Completed,
    CompletedByDefault
}
