import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import store from '@/store'

export const getCountryCode = () => {
    return store.getters[AuthGetterTypes.GetCountryCode]
}

export const isCountryAvailableForPayments = () => {
    return store.getters.feature('onlinepayments')
}

export const isImpersonator = () => {
    return store.getters[AuthGetterTypes.IsImpersonator]
}

export const getCurrency = () => {
    return store.getters[AuthGetterTypes.GetCurrency]
}

export const getMinPriceForVirtualScheduleService = () => {
    return store.getters.minPriceForVirtualScheduleService
}
