import { ActivationSystemTaskKind } from '../../enums'
import { ActivationSystemGroup, ActivationSystemTask } from '../interfaces'
import { mapGroupTaskFromTask } from '../mappers'

/**
 * Group the given `taskList` by `kind`
 * @param taskList - List of tasks to group
 */
export const groupTaskWithStatusListByKind = (taskList: ActivationSystemTask[]): ActivationSystemGroup[] => {
    return taskList.reduce<ActivationSystemGroup[]>((acc, currentValue) => {
        const groupKind = currentValue.kind
        const groupTask = mapGroupTaskFromTask(currentValue)

        if (!acc.length) {
            acc.push({ kind: groupKind, tasks: [groupTask] })

            return acc
        }

        if (acc[acc.length - 1].kind !== groupKind) {
            acc.push({ kind: groupKind, tasks: [groupTask] })

            return acc
        }

        acc[acc.length - 1].tasks.push(groupTask)

        return acc
    }, [])
}

/**
 * Returns `true` if the given `group` is a `Book` group
 * @param group - Group to check
 */
export const isGroupBook = (group: ActivationSystemGroup): boolean => {
    return group.kind === ActivationSystemTaskKind.Book
}

/**
 * Returns `true` if the given `group` is a `Know` group
 * @param group - Group to check
 */
export const isGroupKnow = (group: ActivationSystemGroup): boolean => {
    return group.kind === ActivationSystemTaskKind.Know
}

/**
 * Returns `true` if the given `group` is a `Trust` group
 * @param group - Group to check
 */
export const isGroupTrust = (group: ActivationSystemGroup): boolean => {
    return group.kind === ActivationSystemTaskKind.Trust
}
