import { ActivationSystemTaskKind } from '../../enums'

interface ActivationSystemTranslationModalWelcomeGroup {
    description: string
    title: string
}

interface ActivationSystemTranslationModalWelcomeHeader {
    title: string
}

interface ActivationSystemTranslationModalWelcomeBody {
    group: Record<ActivationSystemTaskKind, ActivationSystemTranslationModalWelcomeGroup>
    title: string
}

interface ActivationSystemTranslationModalWelcomeFooter {
    ok: string
    optOut: string
    skip: string
}

interface ActivationSystemTranslationModalWelcome {
    body: ActivationSystemTranslationModalWelcomeBody
    footer: ActivationSystemTranslationModalWelcomeFooter
    header: ActivationSystemTranslationModalWelcomeHeader
}

const TRANSLATION_MODAL_WELCOME_GROUP_KNOW: ActivationSystemTranslationModalWelcomeGroup = {
    title: 'saas-setup-guide-welcome-banner-descr-know-header',
    description: 'saas-setup-guide-welcome-banner-descr-know-descr'
}
const TRANSLATION_MODAL_WELCOME_GROUP_TRUST: ActivationSystemTranslationModalWelcomeGroup = {
    title: 'saas-setup-guide-welcome-banner-descr-trust-header',
    description: 'saas-setup-guide-welcome-banner-descr-trust-descr'
}
const TRANSLATION_MODAL_WELCOME_GROUP_BOOK: ActivationSystemTranslationModalWelcomeGroup = {
    title: 'saas-setup-guide-welcome-banner-descr-book-header',
    description: 'saas-setup-guide-welcome-banner-descr-book-descr'
}

const TRANSLATION_MODAL_WELCOME_HEADER: ActivationSystemTranslationModalWelcomeHeader = {
    title: 'saas-setup-guide-welcome-banner-modal-header'
}

const TRANSLATION_MODAL_WELCOME_BODY: ActivationSystemTranslationModalWelcomeBody = {
    title: 'saas-setup-guide-welcome-banner-header',
    group: {
        [ActivationSystemTaskKind.Know]: TRANSLATION_MODAL_WELCOME_GROUP_KNOW,
        [ActivationSystemTaskKind.Trust]: TRANSLATION_MODAL_WELCOME_GROUP_TRUST,
        [ActivationSystemTaskKind.Book]: TRANSLATION_MODAL_WELCOME_GROUP_BOOK
    }
}

const TRANSLATION_MODAL_WELCOME_FOOTER: ActivationSystemTranslationModalWelcomeFooter = {
    optOut: 'saas-setup-guide-welcome-banner-opt-out-descr',
    ok: 'saas-setup-guide-welcome-banner-cta-go-to-setup-guide',
    skip: 'saas-setup-guide-welcome-banner-cta-not-now'
}

const TRANSLATION_MODAL_WELCOME: ActivationSystemTranslationModalWelcome = {
    header: TRANSLATION_MODAL_WELCOME_HEADER,
    footer: TRANSLATION_MODAL_WELCOME_FOOTER,
    body: TRANSLATION_MODAL_WELCOME_BODY
}

export const getTranslationModalWelcome = () => {
    return TRANSLATION_MODAL_WELCOME
}
