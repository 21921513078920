import { ActivationSystemTaskName } from '../interfaces'

/**
 * Avoid names longer than 37 characters as we will be using SETUP_GUIDE__{task name} as tracking property
 * and they have a limit of 50 characters.
 * Whenever not possible, ensure the first 37 characters are not the same, since they will be trimmed.
 */

const NAME_PROFILE_CREATE: ActivationSystemTaskName = 'profile::create'
const NAME_PROFILE_UPDATE: ActivationSystemTaskName = 'profile::update'

const NAME_BOOKING_CREATE_ADMIN_FIRST: ActivationSystemTaskName = 'booking::create::first-admin-booking'
const NAME_BOOKING_UPDATE_ONLINE_ACTIVATION: ActivationSystemTaskName = 'booking::update::online-booking-activation'
const NAME_BOOKING_UPDATE_ONLINE_CHANNEL: ActivationSystemTaskName = 'booking::update::online-booking-channel'

// const NAME_SCHEDULE_UPDATE_WORKING_PERIOD: ActivationSystemTaskName = 'schedule::update::working-period'

const NAME_WIZARD_UPDATE: ActivationSystemTaskName = 'wizard::update'

const NAME_PATIENT_IMPORT: ActivationSystemTaskName = 'patient::import'

const NAME_USER_CREATE_SECRETARY: ActivationSystemTaskName = 'user::create::secretary'

const NAME_SERVICE_CREATE_MORE: ActivationSystemTaskName = 'service::create::more'

const NAME_VIDEO_LIBRARY_SHOW: ActivationSystemTaskName = 'video-library::show'

const NAME_MOBILE_APP_DOWNLOAD: ActivationSystemTaskName = 'mobile-app::download'

export const TASK_NAME_PROFILE = {
    CREATE: NAME_PROFILE_CREATE,
    UPDATE: NAME_PROFILE_UPDATE
}

export const TASK_NAME_BOOKING = {
    CREATE_ADMIN_FIRST: NAME_BOOKING_CREATE_ADMIN_FIRST,
    UPDATE_ONLINE_ACTIVATION: NAME_BOOKING_UPDATE_ONLINE_ACTIVATION,
    UPDATE_ONLINE_CHANNEL: NAME_BOOKING_UPDATE_ONLINE_CHANNEL
}

// export const TASK_NAME_SCHEDULE = {
//     UPDATE_WORKING_PERIOD: NAME_SCHEDULE_UPDATE_WORKING_PERIOD
// }

export const TASK_NAME_WIZARD = {
    UPDATE: NAME_WIZARD_UPDATE
}

export const TASK_NAME_PATIENT = {
    IMPORT: NAME_PATIENT_IMPORT
}

export const TASK_NAME_USER = {
    CREATE_SECRETARY: NAME_USER_CREATE_SECRETARY
}

export const TASK_NAME_SERVICE = {
    CREATE_MORE: NAME_SERVICE_CREATE_MORE
}

export const TASK_NAME_VIDEO_LIBRARY = {
    SHOW: NAME_VIDEO_LIBRARY_SHOW
}

export const TASK_NAME_MOBILE_APP = {
    DOWNLOAD: NAME_MOBILE_APP_DOWNLOAD
}

/**
 * NOTE: Added as comment the tasks in the completion state
 * NAME_USER_CREATE_SECRETARY, NAME_SERVICE_CREATE_MORE, NAME_VIDEO_LIBRARY_SHOW
 * as they are not received from the taskList in BE.
 * Some of those are being converted to proper tasks and then those will be uncommented.
 */
export const TASK_NAME_AVAILABLE_LIST: ActivationSystemTaskName[] = [
    NAME_PROFILE_CREATE,
    NAME_PROFILE_UPDATE,
    NAME_BOOKING_CREATE_ADMIN_FIRST,
    NAME_BOOKING_UPDATE_ONLINE_ACTIVATION,
    NAME_BOOKING_UPDATE_ONLINE_CHANNEL,
    // NAME_SCHEDULE_UPDATE_WORKING_PERIOD,
    NAME_WIZARD_UPDATE,
    NAME_PATIENT_IMPORT,
    NAME_USER_CREATE_SECRETARY,
    // NAME_SERVICE_CREATE_MORE,
    // NAME_VIDEO_LIBRARY_SHOW,
    NAME_MOBILE_APP_DOWNLOAD
]
