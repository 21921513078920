export const ADMIN_ROUTES = {
    ADMIN: 'admin',
    PANEL: 'admin-panel',
    ACCESS: 'admin-access',
    IMPORT: 'admin-import',
    IMPORT_LIST: 'admin-import-list',
    SERVICE_LIST: 'admin-service-list',
    SERVICE_IMPORT_WIZARD: 'admin-service-import',
    SERVICE_IMPORT_LIST: 'admin-service-import-list',
    SERVICE_IMPORT_DETAIL: 'admin-service-import-detail',
    CALENDAR_APP_DASHBOARD: 'calendar-app-dashboard',
    SCHEDULE_TOOLS: 'schedules-tools'
}
