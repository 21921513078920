import { reactive } from 'vue'

import monitor from '@/bootstrap/monitor/monitor'
import { SeverityLevels } from '@/bootstrap/monitor/monitor.constants'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { ProfileEntity } from '@/features/auth/domain/interfaces/profile.interfaces'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { FeaturePermissionsType } from '@/features/packaging/domain/enums/featurePermissions.enum'
import {
    AddressFeature,
    DefaultFailSafeStrategy,
    TierPricingSchedule
} from '@/features/packaging/domain/interfaces/features.interfaces'
import { defaultFailSafeStrategyByCountry } from '@/features/packaging/utils/failSafeStrategy/defaultFailSafeStrategy'
import store from '@/store'

interface PackagingState {
    featuresInformation: AddressFeature[]
    isFetchingFeatures: boolean
    isFetchingSchedules: boolean
    isInitialized: boolean
    schedulesInformation: TierPricingSchedule[]
}

const packagingState = reactive<PackagingState>({
    featuresInformation: [],
    isFetchingFeatures: false,
    isFetchingSchedules: false,
    isInitialized: false,
    schedulesInformation: []
})

let packagingService: any
const PLANS_AVAILABLE = ['promo', 'starter', 'plus', 'vip']
const FREE_PLAN = 'free'
const PRIVATE_TYPE = 'private'

// eslint-disable-next-line sonarjs/cognitive-complexity
export function usePackaging() {
    const isAdvanceAppointmentUpsellingEnabled = ensureToggleIsEnabled('TierPricingAdvanceAppointmentUpselling', true)
    const isPackagingEhrEnabled = ensureToggleIsEnabled('EhrUsesPackageApp', true)
    const isEhrUpsellingEnabled = isPackagingEhrEnabled
        ? ensureToggleIsEnabled('TierPricingShowUpselling', true)
        : false
    const isPostOfficeUpsellingEnabled = isEhrUpsellingEnabled
    const isUsePackageAppFailSafeStrategyEnabled = ensureToggleIsEnabled('UsePackageAppFailSafeStrategy', true)

    const isUsingFreemiumPlans = ensureToggleIsEnabled('UsesFreemiumPlans', true)
    const isHideFreeDoctorClinicEnabled = ensureToggleIsEnabled('hideFreeDoctorClinicsEHR', true)

    async function initService() {
        try {
            if (packagingService) {
                return
            }
            const module = await import(
                /* webpackChunkName: "packaging-service" */ '@/features/packaging/domain/packaging.service'
            )
            packagingService = module.default
        } catch (e: any) {
            e.message = `Error loading packagingService module: ${e.message}`
            monitor.sendException(e)
        }
    }

    function canUsePackaging(): boolean {
        const isSaasOnly = isUsingFreemiumPlans
            ? false
            : store.getters[AuthGetterTypes.IsSaasOnly]?.isActive && !store.getters[AuthGetterTypes.GetCommercialType]

        return store.getters[AuthGetterTypes.IsDoctor] && !isSaasOnly
    }

    async function fetchPackagingSchedules() {
        try {
            packagingState.isFetchingSchedules = true
            packagingState.schedulesInformation = await packagingService.getTierPricingSchedules()
        } catch (error) {
            monitor.send('Packaging failed to initialize - schedules', {
                level: SeverityLevels.Error,
                extra: {
                    error
                }
            } as any)
        } finally {
            packagingState.isFetchingSchedules = false
        }
    }

    async function fetchPackagingFeatures(userId: number) {
        try {
            packagingState.isFetchingFeatures = true
            packagingState.featuresInformation = await packagingService.getAddressFeaturesInformation(userId)
        } catch (error) {
            monitor.send('Packaging failed to initialize - features', {
                level: SeverityLevels.Error,
                extra: {
                    error
                }
            } as any)
        } finally {
            packagingState.isFetchingFeatures = false
        }
    }

    async function initializePackaging(user: ProfileEntity): Promise<void> {
        if (!canUsePackaging()) {
            return
        }

        await initService()

        const userId = Number(user.docplannerDoctorId)
        await Promise.all([fetchPackagingSchedules(), fetchPackagingFeatures(userId)])

        packagingState.isInitialized = true
    }

    function isPackagingLoading(): boolean {
        return packagingState.isFetchingFeatures || packagingState.isFetchingSchedules
    }

    function getCountry(): string {
        return store.getters[AuthGetterTypes.GetCountryCode].toLowerCase()
    }

    function getDefaultFailSafe(): DefaultFailSafeStrategy | undefined {
        const country = getCountry()
        return defaultFailSafeStrategyByCountry.find(f => f.country === country)
    }

    function getPackageFeatures(): AddressFeature[] {
        return packagingState.featuresInformation
    }

    function getPackageSchedules(): TierPricingSchedule[] {
        return packagingState.schedulesInformation
    }

    function getIsPrivatePremiumUser(): boolean {
        return getPackageFeatures().some(({ plans, type }) => {
            return type === PRIVATE_TYPE && !plans.includes(FREE_PLAN)
        })
    }

    function getFeatureFailSafe(featureName: FeaturePermissionsType): string | undefined {
        const failSafe = getDefaultFailSafe()
        return failSafe?.features.find(f => f.includes(featureName))
    }

    function hasFeatureBySchedule(scheduleId: number, featureName: FeaturePermissionsType): boolean {
        if (!canUsePackaging() || isPackagingLoading()) {
            return true
        }

        const features = getPackageFeatures()
        const schedule = getPackageSchedules()

        if (isUsePackageAppFailSafeStrategyEnabled) {
            const feature = getDefaultFailSafe()?.features.find((f: string) => f.includes(featureName))

            return Boolean(feature)
        }

        const feature = features.find(f => {
            const scheduleAddress = schedule?.find(s => s.saasScheduleId === scheduleId)?.mpDocplannerDoctorAddressId
            return scheduleAddress && f.addressId === scheduleAddress && f.features.includes(featureName)
        })

        return Boolean(feature)
    }

    function hasFeatureForAtLeastOneSchedule(featureName: FeaturePermissionsType): boolean {
        if (!canUsePackaging() || isPackagingLoading()) {
            return true
        }

        const features = getPackageFeatures()

        const feature = isUsePackageAppFailSafeStrategyEnabled
            ? getFeatureFailSafe(featureName)
            : features?.find(f => f.features.includes(featureName))
        return Boolean(feature)
    }

    function hasPlan(): boolean {
        if (!canUsePackaging()) {
            const commercialType = store.getters[AuthGetterTypes.GetCommercialType]
            return PLANS_AVAILABLE.includes(commercialType)
        }

        const features = getPackageFeatures()

        return PLANS_AVAILABLE.some(value => features[0]?.plans.includes(value))
    }

    function getScheduleIdFromPatientOwnerId(ownerId: number): number | null {
        const authUserId = store.getters[AuthGetterTypes.GetUserId]
        const schedules = getPackageSchedules()
        if (ownerId === authUserId) {
            const privateSchedule = schedules.find(s => s.medicalCenterId === null)
            return privateSchedule?.saasScheduleId || null
        }
        const schedule = schedules.find(s => s.medicalCenterId === ownerId)
        return schedule?.saasScheduleId || null
    }

    function hasFeatureByPatientOwnerId(ownerId: number, featureName: FeaturePermissionsType): boolean {
        if (!canUsePackaging() || isPackagingLoading()) {
            return true
        }
        if (isHideFreeDoctorClinicEnabled || isUsePackageAppFailSafeStrategyEnabled) {
            return hasFeatureForAtLeastOneSchedule(featureName)
        }
        const scheduleId = getScheduleIdFromPatientOwnerId(ownerId)
        return scheduleId ? hasFeatureBySchedule(scheduleId, featureName) : true
    }

    function hasFeatureStartingWith(prefix: string) {
        if (!canUsePackaging() || isPackagingLoading()) {
            return true
        }
        const features = getPackageFeatures()

        return features.some(f => {
            return f.features.some(featureString => featureString.startsWith(prefix))
        })
    }

    function hasAtLeastOneAddressWithPlan(plan: string): boolean {
        if (!canUsePackaging()) {
            return true
        }

        const features = getPackageFeatures()

        return features.some(f => {
            return f.plans.includes(plan)
        })
    }

    return {
        packagingState,
        initializePackaging,
        canUsePackaging,
        isAdvanceAppointmentUpsellingEnabled,
        isEhrUpsellingEnabled,
        isPackagingEhrEnabled,
        isPostOfficeUpsellingEnabled,
        getIsPrivatePremiumUser,
        hasFeatureBySchedule,
        hasFeatureForAtLeastOneSchedule,
        hasPlan,
        hasFeatureByPatientOwnerId,
        hasFeatureStartingWith,
        hasAtLeastOneAddressWithPlan
    }
}
