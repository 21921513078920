import { createAgent } from '@/api/utils/agent'
import HttpClient from '@/api/utils/HttpClient'
import { apiUrl } from '@/utils/url'

const httpClientAPI = new HttpClient({
    baseURL: `${apiUrl}/api/`
})

export const { get, post, remove, put } = createAgent(httpClientAPI.instance)

export default httpClientAPI
