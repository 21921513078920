import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import mapSettingsResponse from '@/core/api/utils/mapSettingResponse'
import { NinOptions } from '@/features/admin/domain/enums/schedule.enums'
import { ScheduleAuditLog } from '@/features/schedule/domain/interfaces/schedule.interface'

import {
    ClinicInvitationNotLogged,
    DeletedSchedule,
    GruppoSettings,
    HubspotInfo,
    ItemPriceRenewal,
    OwnershipMigration,
    ScheduleFeature
} from '../domain/interfaces/admin.interfaces'

const URL_BASE = 'admin'
const URL_BASE_SETTINGS = 'settings'

const URL_CAMPAIGNS_SETTING = `${URL_BASE}/campaigns-setting`
const URL_ENABLE_GCAL = `${URL_BASE}/external-calendar/enable-gcal`
const URL_HUBSPOT_INFO = `${URL_BASE}/hubspot`
const URL_UNLINK_USER = `${URL_BASE}/users/:userId/unlink`
const URL_ICLINIC_HELPER = `${URL_BASE}/iclinic-helper`

const URL_PMS_SYNC_ROLLBACK = `${URL_BASE}/pms-sync/{countryCode}{userId}/stats`
const URL_PMS_SYNC_START_ROLLBACK = `${URL_BASE}/pms-sync/{countryCode}{userId}/rollback`

const URL_SHARE_PATIENTS_DATA = `${URL_BASE}/clinics/markassharepatientdata`
const URL_HIDE_PATIENTS_DATA = `${URL_BASE}/clinics/markashidepatientdata`

const URL_SCHEDULE = `${URL_BASE}/schedule`
const URL_SCHEDULE_SYNC_SERVICES = `${URL_SCHEDULE}/:scheduleId/syncservices`
const URL_SCHEDULES = `${URL_BASE}/schedules`
const URL_SCHEDULES_ACTIVATE = `${URL_SCHEDULES}/:scheduleId/scheduleactivation`
const URL_SCHEDULES_FEATURES = `${URL_SCHEDULES}/:scheduleId/features`
const URL_SCHEDULES_NIN_REQUIREMENT = `${URL_SCHEDULES}/:scheduleId/nin-requirement`
const URL_SCHEDULES_RECOVER = `${URL_SCHEDULES}/:scheduleId/recover`
const URL_SCHEDULES_DELETED = `${URL_SCHEDULES}/get-deleted`
const URL_SCHEDULES_ACTIVATION_HISTORY = `${URL_SCHEDULES}/:scheduleId/status-history`
const URL_SCHEDULES_DISCONNECT_PRIVATE_CLINIC = `${URL_SCHEDULES}/:scheduleId/disconnect-from-clinic-marketplace-only`

const URL_SCHEDULES_CALENDAR_APP_MAPPINGS = `${URL_SCHEDULES}/:scheduleId/calendar-app-mappings`
const URL_SCHEDULES_CALENDAR_SYNCHRONIZE_BUSY_PERIODS = `${URL_SCHEDULES}/:scheduleId/synchronize-calendar-app-busy-periods`

const URL_PATIENTS = `${URL_BASE}/patients`
const URL_PATIENT_IS_BLOCKED = `${URL_PATIENTS}/is-patient-blocked`

const URL_EXPORTS = `${URL_BASE}/exports`
const URL_EXPORT_PATIENTS = `${URL_EXPORTS}/patients`
const URL_EXPORT_APPOINTMENTS = `${URL_EXPORTS}/appointments`
const URL_EXPORT_HEALTH_RECORDS = `${URL_EXPORTS}/healthrecords`

const URL_UNDELETE_PATIENT_ID = `${URL_PATIENTS}/:patientId/undelete`

const URL_UNMASKING = `${URL_BASE}/unmasking`
const URL_UNMASKING_REQUEST = `${URL_UNMASKING}/request`
const URL_UNMASKING_CANCEL = `${URL_UNMASKING}/cancel/:id`
const URL_UNMASKING_CANCEL_FROM_BACKOFFICE = `${URL_UNMASKING}/cancel/:id`
const URL_UNMASKING_TRANSFER = `${URL_UNMASKING}/transfer`

const URL_GRUPPO_ENABLED = `${URL_BASE}/gruppo`
const URL_EXPORT_GRUPPO_DOCTORS_CSV = `${URL_EXPORTS}/doctors`

const KEY_IS_PUBLIC_PRACTITIONER = `isPublicPractitioner`
const URL_IS_PUBLIC_PRACTITIONER = `/${URL_BASE_SETTINGS}/${KEY_IS_PUBLIC_PRACTITIONER}`

const KEY_HAS_BOOKING_REQUESTS = `allowBookingRequests`
const URL_HAS_BOOKING_REQUESTS = `/${URL_BASE_SETTINGS}/${KEY_HAS_BOOKING_REQUESTS}`

const URL_CLINIC_INVITES = `${URL_BASE}/doctor-invitation`
const URL_CLINIC_INVITES_LOGS = `${URL_CLINIC_INVITES}/logs`
const URL_REVERT_CLINIC_INVITE_BY_INVITATION_ID = `${URL_CLINIC_INVITES}/invitation-revert/:doctorInvitationId`
const URL_REVERT_CLINIC_INVITE_FROM_CLINIC = `${URL_BASE}/schedules/:ScheduleId/disconnect-from-clinic`
const URL_GET_SCHEDULES_FROM_CLINIC_NOT_LOGGED = `${URL_SCHEDULES}/medicalcenter-didnt-login`
const URL_PATIENT_OWNERSHIP_MIGRATION = `${URL_BASE}/patient-migration`
const URL_PATIENT_OWNERSHIP_MIGRATION_REVERT = `patients/ownership/rollback`

const URL_UPLOAD_FILE = `${URL_BASE}/plan-change-advice`

const URL_INVALIDATE_PACKAGE_APP_CACHE = `${URL_BASE}/package/cache`

const URL_ACTIVATION_SYSTEM = `${URL_BASE}/activationsystem`
const URL_ACTIVATION_SYSTEM_RESET = `${URL_ACTIVATION_SYSTEM}/tasks/reset`

class AdminRepository {
    syncScheduleServices(scheduleId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_SYNC_SERVICES,
            params: { scheduleId: scheduleId.toString() }
        })
        return httpAPI.post(url)
    }

    getPmsSyncRollbackInfo(userId: number, countryCode: string): Promise<any> {
        let requestUrl = URL_PMS_SYNC_ROLLBACK.replace('{userId}', `${userId}`)
        requestUrl = requestUrl.replace('{countryCode}', `${countryCode}`)
        return httpAPI.get(requestUrl)
    }

    startPmsSyncRollback(userId: number, countryCode: string): Promise<any> {
        let requestUrl = URL_PMS_SYNC_START_ROLLBACK.replace('{userId}', `${userId}`)
        requestUrl = requestUrl.replace('{countryCode}', `${countryCode}`)
        return httpAPI.post(requestUrl)
    }

    deletePmsSyncRollback(userId: number, countryCode: string): Promise<any> {
        let requestUrl = URL_PMS_SYNC_START_ROLLBACK.replace('{userId}', `${userId}`)
        requestUrl = requestUrl.replace('{countryCode}', `${countryCode}`)
        return httpAPI.delete(requestUrl)
    }

    getClinicInvitesLogs(): Promise<any> {
        return httpAPI.get(URL_CLINIC_INVITES_LOGS)
    }

    async isPatientBlocked(patientUrl: string): Promise<any> {
        const { data } = await httpAPI.post(URL_PATIENT_IS_BLOCKED, {
            PatientUrl: patientUrl
        })
        return data
    }

    async parseIclinicFile(file: File): Promise<any> {
        const formData = new FormData()
        formData.append('file', file)

        const response = await httpAPI.post(URL_ICLINIC_HELPER, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
        })

        return response
    }

    markClinicAsSharePatientData(confirm: boolean): Promise<any> {
        return httpAPI.post(URL_SHARE_PATIENTS_DATA, null, {
            params: {
                confirm: confirm.toString()
            }
        })
    }

    markClinicAsHidePatientData(confirm: boolean): Promise<any> {
        return httpAPI.post(URL_HIDE_PATIENTS_DATA, null, {
            params: {
                confirm: confirm.toString()
            }
        })
    }

    requestUnmaskingAccess(message: string, timeLimit: number): Promise<any> {
        return httpAPI.post(
            URL_UNMASKING_REQUEST,
            { message, timeLimit },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async undeletePatient(patientId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_UNDELETE_PATIENT_ID,
            params: { patientId: patientId.toString() }
        })
        const { data } = await httpAPI.post(url)
        return data
    }

    getUnmaskingRequests(): Promise<any> {
        return httpAPI.get(URL_UNMASKING)
    }

    removeAccess(id: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_UNMASKING_CANCEL,
            params: { id: id.toString() }
        })
        return httpAPI.post(url)
    }

    removeAccessFromBackOfficeTool(): Promise<any> {
        return httpAPI.post(URL_UNMASKING_CANCEL_FROM_BACKOFFICE)
    }

    transferAccess(email: string): Promise<any> {
        return httpAPI.post(
            URL_UNMASKING_TRANSFER,
            { email },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    async getHubspotInfo(): Promise<HubspotInfo> {
        const { data } = await httpAPI.get(URL_HUBSPOT_INFO)

        return data
    }

    updateHubspotInfo(contactId?: number, companyId?: number) {
        return httpAPI.post(URL_HUBSPOT_INFO, {
            contactId: contactId?.toString(),
            companyId: companyId?.toString()
        })
    }

    async getScheduleFeatures(scheduleId: number): Promise<ScheduleFeature[]> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_FEATURES,
            params: { scheduleId: scheduleId.toString() }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async updateScheduleFeatures(scheduleId: number, features: ScheduleFeature[]) {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_FEATURES,
            params: { scheduleId: scheduleId.toString() }
        })
        await httpAPI.post(url, features)
    }

    async updateNinRequirement(scheduleId: number, ninOption: NinOptions) {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_NIN_REQUIREMENT,
            params: { scheduleId: scheduleId.toString() }
        })
        await httpAPI.post(url, { option: ninOption })
    }

    sendPatientsDataExport(): Promise<void> {
        return httpAPI.post(URL_EXPORT_PATIENTS)
    }

    sendAppointmentsExport(): Promise<void> {
        return httpAPI.post(URL_EXPORT_APPOINTMENTS)
    }

    sendEhrsExport(): Promise<void> {
        return httpAPI.post(URL_EXPORT_HEALTH_RECORDS)
    }

    enableGCal(): Promise<void> {
        return httpAPI.post(URL_ENABLE_GCAL)
    }

    unlinkUser(userId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_UNLINK_USER,
            params: { userId: userId.toString() }
        })
        return httpAPI.post(url)
    }

    activateSchedule(scheduleId: number) {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_ACTIVATE,
            params: { scheduleId: scheduleId.toString() }
        })

        return httpAPI.put(url)
    }

    getCurrentUserDeletedSchedules(): Promise<DeletedSchedule[]> {
        return httpAPI.get(URL_SCHEDULES_DELETED)
    }

    async getScheduleActivationHistory(scheduleId: number): Promise<ScheduleAuditLog[]> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_ACTIVATION_HISTORY,
            params: { scheduleId: scheduleId.toString() }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    recoverDeletedSchedule(scheduleId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_RECOVER,
            params: { scheduleId: scheduleId.toString() }
        })

        return httpAPI.post(url)
    }

    async calendarMappings(scheduleId: number, from: string, to: string): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_CALENDAR_APP_MAPPINGS,
            params: { scheduleId: scheduleId.toString() },
            query: { from, to }
        })

        const { data } = await httpAPI.get(url)
        return data
    }

    synchronizeBusyPeriods(
        scheduleId: number,
        from: string,
        to: string,
        syncBookings: boolean,
        syncBlocks: boolean
    ): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_CALENDAR_SYNCHRONIZE_BUSY_PERIODS,
            params: { scheduleId: scheduleId.toString() },
            query: { from, to, syncBookings: syncBookings.toString(), syncBlocks: syncBlocks.toString() }
        })

        return httpAPI.post(url)
    }

    async getGruppoSettings(): Promise<GruppoSettings> {
        const { data } = await httpAPI.get(URL_GRUPPO_ENABLED)
        const gruppoSettings: GruppoSettings = {
            enabled: data.result
        }
        return gruppoSettings
    }

    async setGruppoSettings(enabled: boolean): Promise<GruppoSettings> {
        const body: GruppoSettings = {
            enabled
        }
        const { data } = await httpAPI.put(URL_GRUPPO_ENABLED, body)
        return data
    }

    downloadGruppoDoctorsCsv() {
        return httpAPI.get(URL_EXPORT_GRUPPO_DOCTORS_CSV, {
            responseType: 'blob'
        })
    }

    async getIsPublicPractitioner(): Promise<string | boolean> {
        const { data } = await httpAPI.get(URL_IS_PUBLIC_PRACTITIONER)
        return mapSettingsResponse(data)
    }

    async setIsPublicPractitioner(isPublic: boolean) {
        await httpAPI.post(URL_IS_PUBLIC_PRACTITIONER, {
            value: isPublic
        })
    }

    async setBookingRequests(allowBookingRequests: boolean) {
        await httpAPI.post(URL_HAS_BOOKING_REQUESTS, {
            value: allowBookingRequests
        })
    }

    revertClinicInviteByInvitationId(doctorInvitationId: string): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_REVERT_CLINIC_INVITE_BY_INVITATION_ID,
            params: { doctorInvitationId }
        })
        return httpAPI.post(url)
    }

    revertClinicInviteByScheduleId(scheduleId: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_REVERT_CLINIC_INVITE_FROM_CLINIC,
            params: { ScheduleId: scheduleId.toString() }
        })
        return httpAPI.post(url)
    }

    async getSchedulesFromClinicNotLogged(): Promise<ClinicInvitationNotLogged[]> {
        const { data } = await httpAPI.get(URL_GET_SCHEDULES_FROM_CLINIC_NOT_LOGGED)

        return data
    }

    disconnectPrivateSchedule(scheduleId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULES_DISCONNECT_PRIVATE_CLINIC,
            params: { scheduleId: scheduleId.toString() }
        })
        return httpAPI.post(url)
    }

    async getOwnershipMigrations(): Promise<OwnershipMigration[]> {
        const { data } = await httpAPI.get(URL_PATIENT_OWNERSHIP_MIGRATION)

        return data
    }

    revertOwnershipMigration(migrationId: string): Promise<boolean> {
        return httpAPI.post(`${URL_PATIENT_OWNERSHIP_MIGRATION_REVERT}/${migrationId}`)
    }

    uploadPriceTable(content: ItemPriceRenewal[]): Promise<void> {
        return httpAPI.post(URL_UPLOAD_FILE, content)
    }

    invalidatePackageAppCache(): Promise<void> {
        return httpAPI.delete(URL_INVALIDATE_PACKAGE_APP_CACHE)
    }

    activationSystemReset(): Promise<void> {
        return httpAPI.post(URL_ACTIVATION_SYSTEM_RESET)
    }
}

export default new AdminRepository()
