interface ActivationSystemTranslationPageHome {
    description: string
    title: string
}

const TRANSLATION_PAGE_HOME: ActivationSystemTranslationPageHome = {
    title: 'saas-setup-guide-header',
    description: 'saas-setup-guide-subheader'
}

export const getTranslationPageHome = (): ActivationSystemTranslationPageHome => {
    return TRANSLATION_PAGE_HOME
}
