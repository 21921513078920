import { getUrlFromTemplate } from '@dp-vue/utils'

import { get, post } from '@/api/utils/httpClientAPI'

import { ActivationSystemTaskDTO } from '../interfaces'

const URL_BASE = 'activationsystem'
const URL_TASK_LIST = `${URL_BASE}/tasks`
const URL_TASK_COMPLETE = `${URL_BASE}/tasks/complete/:taskId`

export const getTaskList = async () => {
    const url = getUrlFromTemplate({
        template: URL_TASK_LIST
    })

    return get<ActivationSystemTaskDTO[]>(url)
}

export const setTaskCompleted = async (taskId: string) => {
    const url = getUrlFromTemplate({
        template: URL_TASK_COMPLETE,
        params: { taskId }
    })

    return post<boolean, string>(url)
}
