import {
    ActivationSystemTaskName,
    ActivationSystemTaskURL,
    TASK_NAME_PATIENT,
    TASK_NAME_PROFILE,
    TASK_NAME_WIZARD
} from '../../domain'
import { ActivationSystemTaskRouteType, ActivationSystemTaskURLAppType } from '../../enums'

interface RouteInfo {
    newTab: boolean
    path: string
    type: string
}

const TASK_IN_NEW_TAB: ActivationSystemTaskName[] = [
    TASK_NAME_WIZARD.UPDATE,
    TASK_NAME_PATIENT.IMPORT,
    TASK_NAME_PROFILE.UPDATE
]

const openInNewTab = (taskName: ActivationSystemTaskName) => TASK_IN_NEW_TAB.includes(taskName)

export const getRouteInfo = (url: ActivationSystemTaskURL, taskName: ActivationSystemTaskName): RouteInfo => {
    const { domain, path, appType } = url
    const taskInNewTab = openInNewTab(taskName)

    if (!domain || !path || appType === undefined) {
        return { type: '', path: '', newTab: false }
    }

    if (appType !== ActivationSystemTaskURLAppType.SaaS) {
        return {
            type: ActivationSystemTaskRouteType.Global,
            path: `${domain}${path}`,
            newTab: taskInNewTab
        }
    }

    return {
        type: ActivationSystemTaskRouteType.Local,
        path,
        newTab: taskInNewTab
    }
}
