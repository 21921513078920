import type { ActivationSystemTaskName as TaskName } from '../../domain'
import { activationSystemEventBus } from './bus'

enum TaskEvents {
    StartTask = 'StartTask'
}

export const onTaskStarted = (callback: (taskName: TaskName) => void) =>
    activationSystemEventBus.on(TaskEvents.StartTask, callback)
export const offTaskStarted = (callback: (taskName: TaskName) => void) =>
    activationSystemEventBus.off(TaskEvents.StartTask, callback)
export const emitTaskStarted = (taskName: TaskName) => activationSystemEventBus.emit(TaskEvents.StartTask, taskName)
export const createTaskStartedHandler = (fn: (taskName: TaskName) => void) => fn
