import { setupGuide } from '@docplanner/one-tracking'

import { track } from '@/plugins/tracking/index'

import {
    createOnActivationSystemInvitationModalCTAClickedHandler,
    createOnActivationSystemInvitationModalRefussed,
    createOnActivationSystemInvitationSystemShownHandler,
    offActivationSystemInvitationModalCTAClicked,
    offActivationSystemInvitationModalRefussed,
    offActivationSystemInvitationModalShown,
    onActivationSystemInvitationModalCTAClicked,
    onActivationSystemInvitationModalRefussed,
    onActivationSystemInvitationModalShown
} from '../events'

export const startTrackingModal = () => {
    const handlerCTAClicked = createOnActivationSystemInvitationModalCTAClickedHandler(() => {
        track(setupGuide.startFromModal)

        offActivationSystemInvitationModalCTAClicked(handlerCTAClicked)
    })

    const handlerRefussed = createOnActivationSystemInvitationModalRefussed(() => {
        track(setupGuide.refuseModal)

        offActivationSystemInvitationModalRefussed(handlerRefussed)
    })

    const handlerShown = createOnActivationSystemInvitationSystemShownHandler(() => {
        track(setupGuide.invitationModal)

        offActivationSystemInvitationModalShown(handlerShown)
    })

    onActivationSystemInvitationModalCTAClicked(handlerCTAClicked)

    onActivationSystemInvitationModalRefussed(handlerRefussed)

    onActivationSystemInvitationModalShown(handlerShown)
}
