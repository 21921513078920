export const INCOME_SPLIT_EXCEPTION_MODAL = 'IncomeSplitExceptionModal'
export const NEW_MESSAGE_MODAL = 'NewMessageModal'
export const ALERT_MODAL = 'AlertModal'
export const DIALOG_MODAL = 'DialogModal'
export const CONFIRMATION_WITH_TEXTBOX_MODAL = 'ConfirmationWithTextboxModal'
export const CONFIRMATION_MODAL = 'ConfirmationModal'
export const GDPR_MODAL = 'GDPRModal'
export const IMPORT_PATIENTS_NOTIFICATION_MODAL = 'ImportPatientsNotificationModal'
export const IMPORT_PATIENTS_SMS_MODAL = 'ImportPatientsSmsModal'
export const FEATURE_DEACTIVATION_MODAL = 'FeatureDeactivationModal'
export const FEATURE_DEACTIVATION_MODAL_WATSON = 'FeatureDeactivationModalWatson'
export const FEATURE_INFO_MODAL = 'FeatureInfoModal'
export const FEATURE_INFO_MODAL_WATSON = 'FeatureInfoModalWatson'
export const SHARE_PROFILE_MODAL = 'ShareProfileModal'
export const DEBUG_MODAL = 'DebugModal'
export const SCHEDULE_SERVICE_MODAL = 'ScheduleServiceModal'
export const PATIENT_NOTIFY_MODAL = 'PatientNotifyModal'
export const RESCHEDULE_APPOINTMENT_CONFIRMATION_MODAL = 'RescheduleAppointmentConfirmationModal'
export const EHR_UPSELL_PROMPT_MODAL = 'EhrUpsellPromptModal'
export const EHR_UPSELL_PROMPT_CONFIRMATION_MODAL = 'EhrUpsellPromptConfirmationModal'
export const ADVANCE_APPOINTMENT_UPGRADE_MODAL = 'AdvanceAppointmentUpgradeModal'
export const ADVANCE_APPOINTMENT_MODAL = 'AdvanceAppointmentModal'
export const APPOINTMENT_CHANGELOG_MODAL = 'AppointmentChangelogModal'
export const RESOLVE_ALL_PATIENT_REQUESTS_MODAL = 'ResolveAllPatientRequestsModal'
