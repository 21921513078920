import { NavigationGuardNext, Route } from 'vue-router'

import { ROUTE_PAYMENTS_ACTIVATION_NAME, ROUTE_PAYMENTS_OVERVIEW_NAME } from '../../../constants/routes.constants'
import { usePayments } from '../../composables/usePayments'

type NavigationGuardType = {
    next: NavigationGuardNext
    to: Route
}

export const redirectionToLandingPage = async ({ to, next }: NavigationGuardType) => {
    const { isPaymentsAreaEnabled, isPaymentsAvailableToActivate, isPaymentsFeatureAvailable, getPaymentsProfile } =
        usePayments()

    if (!isPaymentsAreaEnabled.value) {
        // NOTE: redirection comes from the file middleware/permissions.js
        return next()
    }

    try {
        await getPaymentsProfile()

        if (!isPaymentsFeatureAvailable.value) {
            return next('/')
        }

        if (to.name === ROUTE_PAYMENTS_ACTIVATION_NAME) {
            if (isPaymentsAvailableToActivate.value) {
                return next()
            }
            return next({ name: ROUTE_PAYMENTS_OVERVIEW_NAME })
        }

        if (isPaymentsAvailableToActivate.value) {
            return next({ name: ROUTE_PAYMENTS_ACTIVATION_NAME })
        }
    } catch {
        return next('/')
    }

    return next()
}
