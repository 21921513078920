import {
    ActivationSystemTaskName,
    TASK_NAME_BOOKING,
    // TASK_NAME_SCHEDULE,
    TASK_NAME_MOBILE_APP,
    TASK_NAME_PATIENT,
    TASK_NAME_PROFILE,
    TASK_NAME_USER,
    TASK_NAME_WIZARD
} from '../../domain'

interface ActivationSystemTranslationTask {
    description: string
    ok: string
    skip: string
    title: string
}

const TRANSLATION_TASK_PROFILE_CREATE: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-know-action-header-create-profile',
    description: '',
    ok: '',
    skip: ''
}

const TRANSLATION_TASK_PROFILE_UPDATE: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-know-action-add-photo-header',
    description: 'saas-setup-guide-group-know-action-add-photo-descr',
    ok: 'saas-setup-guide-group-know-action-add-photo-cta-add',
    skip: 'saas-setup-guide-group-know-action-add-photo-cta-skip'
}

const TRANSLATION_TASK_BOOKING_CREATE_ADMIN_FIRST: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-trust-action-add-visit-header-v2',
    description: 'saas-setup-guide-group-trust-action-add-visit-descr-v2',
    ok: 'saas-setup-guide-group-trust-action-add-visit-cta-add-v2',
    skip: ''
}

const TRANSLATION_TASK_BOOKING_UPDATE_ONLINE_ACTIVATION: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-book-action-go-online-header',
    description: 'saas-setup-guide-group-book-action-go-online-descr',
    ok: 'saas-setup-guide-group-book-action-go-online-cta-go',
    skip: ''
}

// const TRANSLATION_TASK_SCHEDULE_UPDATE_WORKING_PERIOD: ActivationSystemTranslationTask = {
//     title: 'saas-setup-guide-group-book-action-add-working-hours-header',
//     description: 'saas-setup-guide-group-book-action-add-working-hours-descr',
//     ok: 'saas-setup-guide-group-book-action-add-working-hours-cta-add',
//     skip: ''
// }

const TRANSLATION_TASK_WIZARD_UPDATE: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-know-action-set-up-practice-header',
    description: 'saas-setup-guide-group-know-action-set-up-practice-descr',
    ok: 'saas-setup-guide-group-know-action-set-up-practice-cta-set-up',
    skip: ''
}

const TRANSLATION_TASK_MOBILE_APP_DOWNLOAD: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-trust-action-doctor-app-header',
    description: 'saas-setup-guide-group-trust-action-doctor-app-descr',
    ok: 'saas-setup-guide-group-trust-action-doctor-app-cta',
    skip: ''
}

const TRANSLATION_TASK_PATIENT_IMPORT: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-know-action-import-patients-header',
    description: 'saas-setup-guide-group-know-action-import-patients-descr',
    ok: 'saas-setup-guide-group-know-action-import-patients-cta-import',
    skip: 'saas-setup-guide-group-know-action-import-patients-cta-no-patients'
}

const TRANSLATION_TASK_CREATE_SECRETARY: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-trust-action-assistant-account-header',
    description: 'saas-setup-guide-group-trust-action-assistant-account-descr',
    ok: 'saas-setup-guide-group-trust-action-assistant-account-cta-add',
    skip: 'saas-setup-guide-group-trust-action-assistant-account-cta-no-assistant'
}

const TRANSLATION_TASK_BOOKING_UPDATE_ONLINE_CHANNEL: ActivationSystemTranslationTask = {
    title: 'saas-setup-guide-group-book-action-add-booking-channels-header',
    description: 'saas-setup-guide-group-book-action-add-booking-channels-descr',
    ok: 'saas-setup-guide-group-book-action-add-booking-channels-cta-add',
    skip: ''
}

const TASK_TRANSLATION: Record<string, ActivationSystemTranslationTask> = {
    [TASK_NAME_PROFILE.CREATE]: TRANSLATION_TASK_PROFILE_CREATE,
    [TASK_NAME_PROFILE.UPDATE]: TRANSLATION_TASK_PROFILE_UPDATE,
    [TASK_NAME_BOOKING.CREATE_ADMIN_FIRST]: TRANSLATION_TASK_BOOKING_CREATE_ADMIN_FIRST,
    [TASK_NAME_BOOKING.UPDATE_ONLINE_ACTIVATION]: TRANSLATION_TASK_BOOKING_UPDATE_ONLINE_ACTIVATION,
    [TASK_NAME_BOOKING.UPDATE_ONLINE_CHANNEL]: TRANSLATION_TASK_BOOKING_UPDATE_ONLINE_CHANNEL,
    // [TASK_NAME_SCHEDULE.UPDATE_WORKING_PERIOD]: TRANSLATION_TASK_SCHEDULE_UPDATE_WORKING_PERIOD,
    [TASK_NAME_WIZARD.UPDATE]: TRANSLATION_TASK_WIZARD_UPDATE,
    [TASK_NAME_MOBILE_APP.DOWNLOAD]: TRANSLATION_TASK_MOBILE_APP_DOWNLOAD,
    [TASK_NAME_PATIENT.IMPORT]: TRANSLATION_TASK_PATIENT_IMPORT,
    [TASK_NAME_USER.CREATE_SECRETARY]: TRANSLATION_TASK_CREATE_SECRETARY
}

export const getTranslationTaskFromName = (name: ActivationSystemTaskName): ActivationSystemTranslationTask => {
    if (!TASK_TRANSLATION[name]) {
        return { description: '', title: '', ok: '', skip: '' }
    }

    return TASK_TRANSLATION[name]
}
