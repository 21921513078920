import { activationSystemEventBus } from './bus'

enum ActivationSystemEvents {
    InvitationModalCTAClicked = 'InvitationModalCTAClicked',
    InvitationModalRefussed = 'InvitationModalRefussed',
    InvitationModalShown = 'InvitationModalShown'
}

export const onActivationSystemInvitationModalCTAClicked = (callback: () => void) =>
    activationSystemEventBus.on(ActivationSystemEvents.InvitationModalCTAClicked, callback)
export const offActivationSystemInvitationModalCTAClicked = (callback: () => void) =>
    activationSystemEventBus.off(ActivationSystemEvents.InvitationModalCTAClicked, callback)
export const emitActivationSystemInvitationModalCTAClicked = () =>
    activationSystemEventBus.emit(ActivationSystemEvents.InvitationModalCTAClicked)
export const createOnActivationSystemInvitationModalCTAClickedHandler = (fn: () => void) => fn

export const onActivationSystemInvitationModalRefussed = (callback: () => void) =>
    activationSystemEventBus.on(ActivationSystemEvents.InvitationModalRefussed, callback)
export const offActivationSystemInvitationModalRefussed = (callback: () => void) =>
    activationSystemEventBus.off(ActivationSystemEvents.InvitationModalRefussed, callback)
export const emitActivationSystemInvitationModalRefussed = () =>
    activationSystemEventBus.emit(ActivationSystemEvents.InvitationModalRefussed)
export const createOnActivationSystemInvitationModalRefussed = (fn: () => void) => fn

export const onActivationSystemInvitationModalShown = (callback: () => void) =>
    activationSystemEventBus.on(ActivationSystemEvents.InvitationModalShown, callback)
export const offActivationSystemInvitationModalShown = (callback: () => void) =>
    activationSystemEventBus.off(ActivationSystemEvents.InvitationModalShown, callback)
export const emitActivationSystemInvitationModalShown = () =>
    activationSystemEventBus.emit(ActivationSystemEvents.InvitationModalShown)
export const createOnActivationSystemInvitationSystemShownHandler = (fn: () => void) => fn
