import { computed, ref } from 'vue'

import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'

import { PAGINATION_DEFAULT } from '../../constants/table.constants'
import { PaymentsAccountType } from '../../domain/enums'
import { PaymentsData, PaymentsProfile } from '../../domain/interfaces'
import PaymentsService from '../../domain/payments.service'
import { getPaymentsNavigationOption } from '../utils'

const payments = ref<PaymentsData>({ page: [], pagination: PAGINATION_DEFAULT })
const paymentsUserProfile = ref<PaymentsProfile>()
const isLoading = ref<boolean>(false)

let ongoingRequest: Promise<void> | null = null

export function usePayments() {
    const getPayments = async (pageNumber: number, pageSize: number) => {
        try {
            payments.value = await PaymentsService.getPayments(pageNumber, pageSize)
        } catch {
        } finally {
            isLoading.value = false
        }
    }

    const resetPayments = () => {
        payments.value = { page: [], pagination: PAGINATION_DEFAULT }
    }

    function getPaymentsProfile({ cache = true } = {}) {
        if (ongoingRequest) return ongoingRequest
        if (paymentsUserProfile.value && cache) return

        isLoading.value = true

        ongoingRequest = PaymentsService.getPaymentsProfile({ cache })
            .then(profile => {
                paymentsUserProfile.value = profile
            })
            .catch(() => {})
            .finally(() => {
                ongoingRequest = null
                isLoading.value = false
            })

        return ongoingRequest
    }

    const isUserAllowedToMigrate = computed(
        () =>
            paymentsUserProfile.value?.fee.isAllowedToMigrate &&
            paymentsUserProfile.value?.fee.paymentFee !== null &&
            paymentsUserProfile.value?.fee.migrationPaymentFee !== null &&
            paymentsUserProfile.value?.accountType === PaymentsAccountType.StripeCustom
    )

    const isPaymentsFeatureActive = computed(() => paymentsUserProfile.value?.accountType !== PaymentsAccountType.None)

    const isPaymentsFeatureAvailable = computed(
        () =>
            paymentsUserProfile.value &&
            (paymentsUserProfile.value.canOnboard || paymentsUserProfile.value.accountType !== PaymentsAccountType.None)
    )

    const isPaymentsAreaEnabled = computed(() => ensureToggleIsEnabled('PaymentsArea', true))
    const isPaymentsRequestsEnabled = computed(() => {
        const userHasSingleCustomerId = paymentsUserProfile.value?.customerIds.length === 1
        return ensureToggleIsEnabled('PaymentsRequests', true) && userHasSingleCustomerId
    })
    const isPaymentsVouchersEnabled = computed(
        () => ensureToggleIsEnabled('VouchersPaymentsRequests', true) && isPaymentsRequestsEnabled.value
    )

    const paymentsMenuOption = computed(() =>
        isPaymentsFeatureAvailable.value ? getPaymentsNavigationOption() : undefined
    )

    const isPaymentsAvailableToActivate = computed(
        () =>
            paymentsUserProfile.value?.canOnboard && paymentsUserProfile.value?.accountType === PaymentsAccountType.None
    )

    return {
        getPayments,
        resetPayments,
        payments,
        getPaymentsProfile,
        paymentsUserProfile,
        paymentsMenuOption,
        isPaymentsFeatureActive,
        isPaymentsFeatureAvailable,
        isPaymentsRequestsEnabled,
        isPaymentsAreaEnabled,
        isPaymentsVouchersEnabled,
        isUserAllowedToMigrate,
        isPaymentsAvailableToActivate,
        isLoading
    }
}
