import { ActivationSystemTaskKind } from '../../enums'

interface ActivationSystemTranslationGroup {
    title: string
}

const TRANSLATION_GROUP_KNOW: ActivationSystemTranslationGroup = {
    title: 'saas-setup-guide-group-know-header'
}
const TRANSLATION_GROUP_TRUST: ActivationSystemTranslationGroup = {
    title: 'saas-setup-guide-group-trust-header'
}
const TRANSLATION_GROUP_BOOK: ActivationSystemTranslationGroup = {
    title: 'saas-setup-guide-group-book-header'
}

const TRANSLATION_GROUP: Record<ActivationSystemTaskKind, ActivationSystemTranslationGroup> = {
    [ActivationSystemTaskKind.Know]: TRANSLATION_GROUP_KNOW,
    [ActivationSystemTaskKind.Trust]: TRANSLATION_GROUP_TRUST,
    [ActivationSystemTaskKind.Book]: TRANSLATION_GROUP_BOOK
}

export const getTranslationGroupFromKind = (kind: ActivationSystemTaskKind): ActivationSystemTranslationGroup => {
    if (!TRANSLATION_GROUP[kind]) {
        return { title: '' }
    }

    return TRANSLATION_GROUP[kind]
}
