import { RouteConfig } from 'vue-router'

import { ACTIVATION_SYSTEM_ROUTE_NAME, ACTIVATION_SYSTEM_ROUTE_PATH } from '../constants'

const ActivationSystem = () =>
    import(/* webpackChunkName: "activation-system-home" */ '../views').then(module => module.ActivationSystem)

export const ACTIVATION_SYSTEM_ROUTE: RouteConfig = {
    path: ACTIVATION_SYSTEM_ROUTE_PATH,
    name: ACTIVATION_SYSTEM_ROUTE_NAME,
    component: ActivationSystem,
    meta: {
        uninavName: 'activation-system'
    }
}
