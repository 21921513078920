import { activationSystemEventBus } from './bus'

enum ActivationSystemEvents {
    MainPageExited = 'MainPageExited',
    MainPageLoaded = 'MainPageLoaded'
}

export const onActivationSystemMainPageLoaded = (callback: () => void) =>
    activationSystemEventBus.on(ActivationSystemEvents.MainPageLoaded, callback)
export const offActivationSystemMainPageLoaded = (callback: () => void) =>
    activationSystemEventBus.off(ActivationSystemEvents.MainPageLoaded, callback)
export const emitActivationSystemMainPageLoaded = () =>
    activationSystemEventBus.emit(ActivationSystemEvents.MainPageLoaded)
export const createActivationSystemMainPageLoadedHandler = (fn: () => void) => fn

export const onActivationSystemMainPageExited = (callback: () => void) =>
    activationSystemEventBus.on(ActivationSystemEvents.MainPageExited, callback)
export const offActivationSystemMainPageExited = (callback: () => void) =>
    activationSystemEventBus.off(ActivationSystemEvents.MainPageExited, callback)
export const emitActivationSystemMainPageExited = () =>
    activationSystemEventBus.emit(ActivationSystemEvents.MainPageExited)
export const createActivationSystemMainPageExitedHandler = (fn: () => void) => fn
