import download from 'downloadjs'

import Services from '@/api/Services'
import { MAX_FILE_NAME_LENGTH, MAX_FILE_SIZE } from '@/core/ui/constants/file.constants'
import { format } from '@/core/ui/utils'
import repository from '@/features/pms/api/pms.api'
import { PmsCustomField, PmsCustomFieldState, SassLocation } from '@/features/pms/domain/enums/ehr.enums'
import {
    AiAssistantStatus,
    AiAssistantSummaryFeedback,
    Appointment,
    Ehr,
    EpisodeType,
    EpisodeUploadPayload,
    ICD10Code,
    MedicalBackground,
    Patient,
    PmsEvent,
    PmsField,
    PmsFileImageSizing,
    PmsGenerateEpisode,
    PmsHistory,
    PmsHistoryConfig,
    PmsSendEhrChatInput,
    PmsSendEhrInput,
    PmsSendFullEhrInput,
    PrevisitElement,
    Snippet
} from '@/features/pms/domain/interfaces/ehr.interfaces'
import { MEDICAL_BACKGROUND } from '@/features/pms/ui/constants/episode.constants'
import Storage from '@/store/storage'
import { decodeFileName } from '@/utils/file'
import { getFileNameFromContentDisposition } from '@/utils/http'
import { $t } from '@/utils/i18n'
import { parseJSON } from '@/utils/parse-json'
import { getStringWithoutExtension } from '@/utils/string'

import { ProgressCallback } from '../api/interfaces/pms.interfaces'
import { getBackupFieldStorageKey } from './utils/getBackupFieldStorageKey'

const EHR_DRAFT_STATUS = 0

class PmsService {
    getEHRDoctorSpeciality() {
        return repository.getEHRDoctorSpeciality()
    }

    async initField() {
        const specialites = await this.getEHRDoctorSpeciality()
        return [
            {
                specialityId: specialites.id,
                specialityName: specialites.name,
                description: ''
            }
        ]
    }

    async getMedicalBackground(patient: Patient): Promise<any> {
        const medicalBackground = []

        if (patient.patientAllergies) {
            medicalBackground.push({
                id: MEDICAL_BACKGROUND.ALLERGIES,
                title: 'ehr-doctor-allergies',
                emptyTitle: 'mobile-ehr-no-allergy-yet',
                elements: patient.patientAllergies.length ? patient.patientAllergies : [{ description: '' }]
            })
        }

        if (patient.patientMedications) {
            medicalBackground.push({
                id: MEDICAL_BACKGROUND.MEDICATION,
                title: 'ehr-doctor-medication',
                emptyTitle: 'mobile-ehr-no-medication-yet',
                elements: patient.patientMedications.length ? patient.patientMedications : [{ description: '' }]
            })
        }

        if (patient.patientPrecedents) {
            medicalBackground.push({
                id: MEDICAL_BACKGROUND.PRECEDENTS,
                title: 'ehr-doctor-medical-background',
                emptyTitle: 'mobile-ehr-no-medical-background-yet',
                elements: patient.patientPrecedents.length ? patient.patientPrecedents : [{ description: '' }]
            })
        }

        if (patient.patientOtherMedicalInfo) {
            medicalBackground.push({
                id: MEDICAL_BACKGROUND.OTHER,
                title: 'ehr-doctor-other-category-title-just-other',
                emptyTitle: 'mobile-ehr-other-no-information-added-yet',
                elements: patient.patientOtherMedicalInfo.length
                    ? patient.patientOtherMedicalInfo
                    : await this.initField()
            })
        }

        return medicalBackground
    }

    updateMedicalBackground(patientId: number, medicalBackground: Array<MedicalBackground>) {
        const sections: any = medicalBackground.reduce(
            (output, section) => ({
                ...output,
                [section.id]: section
            }),
            {}
        )

        const allergies = sections[MEDICAL_BACKGROUND.ALLERGIES].elements[0].description
        const precedents = sections[MEDICAL_BACKGROUND.PRECEDENTS].elements[0].description
        const medications = sections[MEDICAL_BACKGROUND.MEDICATION].elements[0].description
        const others = sections[MEDICAL_BACKGROUND.OTHER].elements

        const medicalPatientbackground = {
            patientAllergies: { description: allergies },
            patientPrecedents: { description: precedents },
            patientMedications: { description: medications },
            patientOtherMedicalInfo: others
        }
        return repository.updateMedicalBackground(patientId, medicalPatientbackground)
    }

    async getPrevisit(id: number): Promise<PrevisitElement[]> {
        const { filledFields } = await repository.getPrevisit(id)
        return filledFields.filter(({ saasLocationId }) => saasLocationId === SassLocation.MedicalBackground)
    }

    async getEhrHistory(patientId: number, params: PmsHistoryConfig): Promise<PmsHistory> {
        const data = await repository.getHistory(patientId, params)

        const pageRecords = data?.page || []

        return {
            ...data,
            page: pageRecords.map((page: any) => ({
                ...page,
                isDraft: page.status === EHR_DRAFT_STATUS,
                serviceIds: page.services.map(({ id }: any) => id),
                fileIds: page.files.map(({ id }: any) => id),
                customFields: this.parseCustomFields(page.customFields)
            }))
        }
    }

    async getPmsFields(): Promise<PmsField[]> {
        const { customFields } = await repository.getPmsFields()
        return customFields
    }

    async getAdminPmsFields(): Promise<PmsField[]> {
        const { customFields } = await repository.getAdminPmsFields()
        return customFields
    }

    getPmsPhonePictureQrCode(healthRecordId: number): Promise<string> {
        return repository.getPmsPhonePictureQrCode(healthRecordId)
    }

    getPmsPhonePictureLink(healthRecordId: number): Promise<string> {
        return repository.getPmsPhonePictureLink(healthRecordId)
    }

    updatePmsFields(fields: PmsField[]): Promise<number> {
        const fieldsCleaned = fields.filter(({ label }) => label !== '')

        // make sure that the fields will be kept in a right order
        const fieldsOrdered = fieldsCleaned.map((field, index) => ({
            ...field,
            sequenceNumber: index
        }))
        return repository.updatePmsFields(fieldsOrdered)
    }

    async getEpisode(id: number, patient: number): Promise<PmsEvent> {
        const data = await repository.getEpisode(id, patient)
        data.customFields = this.parseCustomFields(data.customFields)
        return data
    }

    async getEpisodeHistory(id: string, patient: number): Promise<PmsEvent> {
        const data = await repository.getEpisodeHistory(id, patient)
        data.customFields = this.parseCustomFields(data.customFields)
        return data
    }

    getAppointment(id: number): Promise<Appointment> {
        return repository.getAppointment(id)
    }

    async getServices(): Promise<any[]> {
        const { data } = await Services().getServices()
        return data.map((service: { id: any; name: any }) => {
            return { id: service.id, value: service.id, text: service.name }
        })
    }

    async getIcdCodes(): Promise<string[]> {
        const data = await repository.getIcdCodes()
        return data.map((code: ICD10Code) => code.description)
    }

    async getExaminationCodes(): Promise<string[]> {
        const data = await repository.getExaminationCodes()
        return data.map((code: ICD10Code) => `${code.subcategory} ${code.description}`)
    }

    parseCustomFields(fields: PmsField[]) {
        return fields.map(field => {
            const fieldType = field.type || -1 // TypeScript is type checking the includes, -1 is a fallback incase

            if ([PmsCustomField.Diagnosis, PmsCustomField.Examination].includes(fieldType)) {
                return {
                    ...field,
                    value: parseJSON(field.value)
                }
            }
            return field
        })
    }

    composeCustomFields(fields: PmsField[]) {
        return fields
            .filter(
                (field: PmsField) =>
                    field.label.replace(/ /g, '').length > 0 && field.state !== PmsCustomFieldState.Removed
            )
            .map((field: PmsField) => {
                if (this.isDiagnosis(field.type) && typeof field.value === 'string') {
                    field.value = { value: field.value, codes: field.codes }
                }
                return field
            })
    }

    generateEpisode({
        fields,
        services,
        patientId,
        eventId,
        id: episodeId,
        files,
        shouldSync,
        typeId
    }: PmsGenerateEpisode): Ehr {
        return {
            isDraft: true,
            customFields: this.composeCustomFields(fields),
            eventId,
            patientId,
            serviceIds: services,
            date: format(new Date()),
            id: episodeId,
            files,
            fileIds: files?.map(({ id }) => id),
            shouldSync,
            typeId
        }
    }

    generateEpisodeUploadPayload({
        fields,
        services,
        patientId,
        eventId,
        id: episodeId,
        shouldSync,
        typeId,
        doctorPrivateNotes,
        AINotes
    }: PmsGenerateEpisode): EpisodeUploadPayload {
        return {
            isDraft: true,
            customFields: this.composeCustomFields(fields),
            eventId,
            patientId,
            serviceIds: services,
            date: format(new Date()),
            id: episodeId,
            shouldSync,
            typeId,
            doctorPrivateNotes,
            iaPrivateNotes: AINotes
        }
    }

    getEpisodeTypes(): Promise<EpisodeType[]> {
        return repository.getEpisodeTypes()
    }

    updateEpisode(episode: Ehr): Promise<EpisodeUploadPayload> {
        return repository.updateEpisode(episode)
    }

    createEpisodeDraft(episode: Ehr): Promise<any> {
        return repository.createEpisode(episode)
    }

    publishEpisode(id: number): Promise<any> {
        return repository.publishEpisode(id)
    }

    discardEpisode(id: number, patient: number): Promise<number> {
        return repository.discardEpisode(id, patient)
    }

    addSection(
        sections: PmsField[],
        index: number,
        fieldLabel: string,
        value?: any,
        fieldType: PmsCustomField = PmsCustomField.LongText
    ): PmsField[] {
        const field: PmsField = {
            label: fieldLabel,
            value: this.getFieldValue(value, fieldType),
            type: fieldType,
            state: this.getFieldState(fieldType),
            sequenceNumber: 0
        }
        sections.splice(index, 0, field)
        return sections
    }

    getFieldValue(value: any, fieldType: PmsCustomField) {
        if (value) {
            return value
        }

        if (this.isDiagnosis(fieldType)) {
            return {
                value: '',
                codes: []
            }
        }

        return ''
    }

    getFieldState(fieldType: PmsCustomField) {
        return fieldType === PmsCustomField.Document ? PmsCustomFieldState.Document : PmsCustomFieldState.Active
    }

    isFieldToRemove(section: PmsField, field: PmsField): boolean {
        if (field.id) {
            return section.id === field.id
        }
        return section.label === field.label
    }

    removeSection(sections: PmsField[], field: PmsField): PmsField[] {
        return sections.map(section => ({
            ...section,
            state: this.isFieldToRemove(section, field) ? PmsCustomFieldState.Removed : (section.state as number),
            value: this.isFieldToRemove(section, field) ? '' : (section.value as string)
        }))
    }

    getHistoryFiles(patientId: number) {
        return repository.getHistoryFiles(patientId.toString())
    }

    previewFile(patientId: number, fileId: number, imageSizing: PmsFileImageSizing, withDownload: boolean) {
        return repository.previewFile(patientId.toString(), fileId.toString(), imageSizing, withDownload)
    }

    uploadFiles(files: File[], id: number, healthRecordId: number, process?: ProgressCallback): Promise<any> {
        const filesUpload = []

        for (let i = 0; i < files.length; i += 1) {
            const file = files[i]
            const decodedName = decodeFileName(file.name)

            if (file.size === undefined || file.size === 0) {
                return Promise.reject(new Error('attach-files-error-message-no-empty-files-allowed'))
            }
            if (file.name.length > MAX_FILE_NAME_LENGTH) {
                return Promise.reject(new Error('attach-files-error-message-new-name-is-too-long'))
            }
            if (file.size >= MAX_FILE_SIZE) {
                return Promise.reject(new Error('attach-files-error-toaster-file-is-too-big'))
            }
            filesUpload.push(repository.addFile(decodedName, file, id, healthRecordId, process))
        }
        return Promise.all(filesUpload)
    }

    async downloadFile(patientId: number, fileId: number) {
        const response = await repository.downloadFile(patientId.toString(), fileId.toString())
        download(response.data, getFileNameFromContentDisposition(response))
    }

    renameFile(patientId: number, fileId: number, name: string) {
        const actualName = getStringWithoutExtension(name)

        return repository.renameFile(patientId.toString(), fileId.toString(), actualName)
    }

    async deleteFile(id: number, patientId: number, files: any[]) {
        await repository.deleteFile(id, patientId)
    }

    processFiles(files: any[], event: ProgressEvent, fileInput: File) {
        return files.map(file => {
            if (file.name === fileInput.name) {
                file.progress = Math.round((event.loaded / event.total) * 100)
            }
            return file
        })
    }

    sendEhrToPatient({
        episodeId,
        fields,
        files,
        email,
        phoneNumber,
        patientId,
        shouldSaveEmail = false,
        shouldSavePhone = false,
        printLayoutToken
    }: PmsSendEhrInput): Promise<any> {
        return repository.sendEhrToPatient({
            episodeId,
            phoneNumber,
            patientId,
            files,
            fields,
            email,
            shouldSaveEmail,
            shouldSavePhone,
            printLayoutToken
        })
    }

    getSnippets(): Promise<Snippet[]> {
        return repository.getSnippets()
    }

    createSnippet(snnipet: Snippet): Promise<number> {
        return repository.createSnippet(snnipet)
    }

    updateSnippet(snippet: Snippet, snippetId: number): Promise<number> {
        return repository.updateSnippet(snippet, snippetId)
    }

    deleteSnippet(id: number) {
        return repository.deleteSnippet(id)
    }

    printAllEpisodeHistory(patientId: number, includeFiles: boolean, printLayoutToken: string) {
        return repository.printAllEpisodeHistory(patientId, includeFiles, printLayoutToken)
    }

    printEpisode(
        episodeId: number,
        patientId: number,
        fieldIds: number[],
        fileIds: number[],
        printLayoutToken?: string
    ): Promise<any> {
        return repository.printEpisode(episodeId, patientId, fieldIds, fileIds, printLayoutToken)
    }

    sendAllEpisodeHistory({
        patientId,
        email,
        phoneNumber,
        printLayoutToken,
        includeFiles,
        shouldSaveEmail = false,
        shouldSavePhone = false
    }: PmsSendFullEhrInput) {
        return repository.sendAllEpisodeHistory({
            patientId,
            email,
            phoneNumber,
            printLayoutToken,
            includeFiles,
            shouldSaveEmail,
            shouldSavePhone
        })
    }

    getSections(patientId: number, query?: string): Promise<any> {
        return repository.getSections(patientId, query)
    }

    checkIfMissingPatientDataFields(healthRecordId: number): Promise<string[]> {
        return repository.checkIfMissingPatientDataFields(healthRecordId)
    }

    isDiagnosis(fieldType: PmsCustomField): boolean {
        return fieldType === PmsCustomField.Diagnosis
    }

    isDocument(fieldType: PmsCustomField): boolean {
        return fieldType === PmsCustomField.Document
    }

    getSectionFields(fields: PmsField[]): PmsField[] | [] {
        return fields.filter(({ type }) => !this.isDocument(type))
    }

    getDocumentFields(fields: PmsField[]): PmsField[] | [] {
        return fields.filter(({ type }) => this.isDocument(type))
    }

    backupFields(healthRecordId: number, fields: PmsField[]): void {
        Storage.set(getBackupFieldStorageKey(healthRecordId), fields)
    }

    restoreBackupFields(healthRecordId: number): PmsField[] {
        const backup = Storage.get(getBackupFieldStorageKey(healthRecordId))

        return backup || []
    }

    clearBackupFields(healthRecordId: number) {
        Storage.remove(getBackupFieldStorageKey(healthRecordId))
    }

    getRecorderToken(healthRecordId: number): Promise<string[]> {
        return repository.getRecorderToken(healthRecordId)
    }

    acceptFieldContent(healthRecordId: number, fieldId: number, accept: boolean): Promise<any> {
        return repository.acceptFieldContent(healthRecordId, fieldId, accept)
    }

    getAiAssistantStatus(episodeId: number): Promise<AiAssistantStatus> {
        return repository.getAiAssistantStatus(episodeId)
    }

    terminateAiAssistant(episodeId: number): Promise<void> {
        return repository.terminateAiAssistant(episodeId)
    }

    sendEhrThroughChat({ episodeId, fields, files, patientId, printLayoutToken }: PmsSendEhrChatInput): Promise<any> {
        return repository.sendEhrThroughChatToPatient({
            episodeId,
            patientId,
            files,
            fields,
            printLayoutToken
        })
    }

    getAiAssistantSummaryFeedback(episodeId: number): Promise<AiAssistantSummaryFeedback> {
        return repository.getAiAssistantSummaryFeedback(episodeId)
    }

    sendAiAssistantFeedback(episodeId: number, data: AiAssistantSummaryFeedback): Promise<void> {
        return repository.sendAiAssistantSummaryFeedback(episodeId, data)
    }
}

export default new PmsService()
