import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle/featureToggle.middleware'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'

import {
    WAITING_LIST_ROUTE,
    WAITING_LIST_ROUTE_NAME,
    WAITING_LIST_ROUTE_ONBOARDING,
    WAITING_LIST_ROUTE_ONBOARDING_NAME
} from '../constants/routes.constants'
import waitingListMiddleware from '../middlewares/waitingList.middleware'

const WaitingListIndex = () => import(/* webpackChunkName: "waiting-list-index" */ '../views/WaitingListIndex.vue')
const WaitingList = () => import(/* webpackChunkName: "waiting-list" */ '../views/WaitingList.vue')
const WaitingListOnboarding = () =>
    import(/* webpackChunkName: "waiting-list-onboarding" */ '../views/WaitingListOnboarding.vue')

export default {
    path: WAITING_LIST_ROUTE,
    component: WaitingListIndex,
    meta: {
        title: 'Waiting List', // TODO: Add translation,
        middleware: [
            featureToggleMiddleware({
                key: 'ShowWaitinglistFeature',
                value: 'true',
                fallbackRouteName: ROUTE_CALENDAR_BASE_NAME
            })
        ]
    },
    children: [
        {
            path: WAITING_LIST_ROUTE_ONBOARDING,
            name: WAITING_LIST_ROUTE_ONBOARDING_NAME,
            component: WaitingListOnboarding,
            meta: {
                title: 'Waiting List Onboarding' // TODO: Add translation
            }
        },
        {
            path: '',
            name: WAITING_LIST_ROUTE_NAME,
            component: WaitingList,
            meta: {
                middleware: [waitingListMiddleware],
                title: 'Waiting List table' // TODO: Add translation
            }
        }
    ]
}
